var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"tab-danger pt-3"},[(_vm.$i18n.locale == 'ar')?_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15 active show'
                  : '',attrs:{"href":"#tab-arabic","role":"tab","data-toggle":"tab","aria-selected":"true"}},[_vm._v(" "+_vm._s(_vm.$t("app.arabic"))+" ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'active show',attrs:{"href":"#tab-english","role":"tab","data-toggle":"tab","aria-selected":"false"}},[_vm._v(" "+_vm._s(_vm.$t("app.english"))+" ")])])]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'active show',attrs:{"href":"#tab-english","role":"tab","data-toggle":"tab","aria-selected":"false"}},[_vm._v(" "+_vm._s(_vm.$t("app.english"))+" ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15 active show'
                  : '',attrs:{"href":"#tab-arabic","role":"tab","data-toggle":"tab","aria-selected":"true"}},[_vm._v(" "+_vm._s(_vm.$t("app.arabic"))+" ")])])]):_vm._e(),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade pt-3",class:_vm.$i18n.locale == 'ar' ? 'active show' : '',attrs:{"role":"tabpanel","id":"tab-arabic"}},[(_vm.hasTitle)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputAR1"}},[_vm._v(" "+_vm._s(_vm.labelTitle)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.title_ar),expression:"row.title_ar"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',attrs:{"id":"inputAR1","type":"text"},domProps:{"value":(_vm.row.title_ar)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "title_ar", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasPosition)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputARTY1"}},[_vm._v(" "+_vm._s(_vm.labelPosition)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.position_ar),expression:"row.position_ar"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',attrs:{"id":"inputARTY1","type":"text"},domProps:{"value":(_vm.row.position_ar)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "position_ar", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasUniversity)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputARMO1"}},[_vm._v(" "+_vm._s(_vm.labelUniversity)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.university_ar),expression:"row.university_ar"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',attrs:{"id":"inputARMO1","type":"text"},domProps:{"value":(_vm.row.university_ar)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "university_ar", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasBody)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"editorAR1"}},[_vm._v(" "+_vm._s(_vm.labelBody)+" ")]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"col-12"},[_c('br'),_c('br')]):_vm._e(),_c('editor',{attrs:{"id":"editorAR1","api-key":_vm.editor.api_key,"init":{
                  height: 300,
                  menubar: _vm.editor.menubar,
                  plugins: _vm.editor.plugins,
                  toolbar: _vm.editor.toolbar,
                }},on:{"input":_vm.onFormChange},model:{value:(_vm.row.body_ar),callback:function ($$v) {_vm.$set(_vm.row, "body_ar", $$v)},expression:"row.body_ar"}})],1):_vm._e()]),_c('div',{staticClass:"tab-pane fade in pt-3",class:_vm.$i18n.locale == 'ar' ? '' : 'active show',attrs:{"role":"tabpanel","id":"tab-english"}},[(_vm.hasTitle)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputEN1"}},[_vm._v(" "+_vm._s(_vm.labelTitle)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.title_en),expression:"row.title_en"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',staticStyle:{"direction":"ltr"},attrs:{"id":"inputEN1","type":"text"},domProps:{"value":(_vm.row.title_en)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "title_en", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasPosition)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputENTY1"}},[_vm._v(" "+_vm._s(_vm.labelPosition)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.position_en),expression:"row.position_en"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',staticStyle:{"direction":"ltr"},attrs:{"id":"inputENTY1","type":"text"},domProps:{"value":(_vm.row.position_en)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "position_en", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasUniversity)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"inputENMO1"}},[_vm._v(" "+_vm._s(_vm.labelUniversity)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.university_en),expression:"row.university_en"}],staticClass:"form-control",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : '',staticStyle:{"direction":"ltr"},attrs:{"id":"inputENMO1","type":"text"},domProps:{"value":(_vm.row.university_en)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row, "university_en", $event.target.value)},_vm.onFormChange]}})]):_vm._e(),(_vm.hasBody)?_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"col-form-label",class:_vm.$i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'float-left',attrs:{"for":"editorEN1"}},[_vm._v(" "+_vm._s(_vm.labelBody)+" ")]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"col-12"},[_c('br'),_c('br')]):_vm._e(),_c('editor',{attrs:{"id":"editorEN1","api-key":_vm.editor.api_key,"init":{
                  height: 300,
                  menubar: _vm.editor.menubar,
                  plugins: _vm.editor.plugins,
                  toolbar: _vm.editor.toolbar,
                }},on:{"input":_vm.onFormChange},model:{value:(_vm.row.body_en),callback:function ($$v) {_vm.$set(_vm.row, "body_en", $$v)},expression:"row.body_en"}})],1):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }